"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _constants = require("./constants");

var _caller = require("./caller");

var _log = _interopRequireDefault(require("../utils/log"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var MicroIframe = function MicroIframe() {
  var _this = this;

  _classCallCheck(this, MicroIframe);

  _defineProperty(this, "init", function (idReceiver) {
    _log["default"].init(_log["default"].LogLevel.all);

    if (_this.config.initialized) {
      _log["default"].warn('MicroIframe already configured');

      return;
    }

    (0, _caller.setCalledByParent)();
    _this.config.initialized = true;
    _this.config.idReceiver = idReceiver;
    if ((0, _caller.isCalledByParent)()) _this.postMessageToParent(_constants.MicroIframeTypes.LOADED);

    if (window.addEventListener) {
      window.addEventListener('message', _this.handleMessage);
    } else {
      window.attachEvent('onmessage', _this.handleMessage);
    }
  });

  _defineProperty(this, "postMessageToIframes", function (type, payload) {
    var idDestination = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

    _this.configTest();

    var iframes = document.getElementsByTagName('iframe');

    for (var i = 0; i < iframes.length; i += 1) {
      iframes[i].contentWindow.postMessage(_this.formatMessage(type, payload, idDestination), '*');
    }
  });

  _defineProperty(this, "postMessageToParent", function (type, payload) {
    _this.configTest();

    window.parent.postMessage(_this.formatMessage(type, payload), '*');
  });

  _defineProperty(this, "subscribe", function (callback) {
    var context = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

    _this.configTest();

    if (!callback || typeof callback !== 'function') {
      throw new TypeError('MicroIframe: required callback function');
    }

    _this.regs = _this.regs.concat([{
      callback: callback,
      context: context
    }]);
    return _this;
  });

  _defineProperty(this, "unsubscribe", function (callback) {
    _this.configTest();

    if (!callback || typeof callback !== 'function') {
      throw new TypeError('MicroIframe: required callback function');
    }

    for (var i = 0; i < _this.regs.length; i += 1) {
      if (_this.regs[i].callback === callback) {
        _this.regs.splice(i, 1);

        return _this;
      }
    }

    return _this;
  });

  this.regs = [];
  this.config = {
    initialized: false,
    IdReceiver: null
  };

  this.handleMessage = function (event) {
    if (event.data && event.data.id === _constants.ID_MICROIFRAME_MSGS && event.data.origin !== _this.config.idReceiver) {
      var _event$data = event.data,
          id = _event$data.id,
          destination = _event$data.destination,
          eventToForward = _objectWithoutProperties(_event$data, ["id", "destination"]);

      if (destination && destination !== _this.config.idReceiver) {
        return;
      }

      _log["default"].debug("MESSAGE FOR ".concat(_this.config.idReceiver), event);

      _this.regs.forEach(function (reg) {
        reg.callback(eventToForward);
      });
    }
  };

  this.configTest = function () {
    if (!_this.config.initialized) {
      throw new TypeError('MicroIframe not configured');
    }
  };

  this.formatMessage = function (type, payload) {
    var destination = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

    _this.configTest();

    return {
      id: _constants.ID_MICROIFRAME_MSGS,
      origin: _this.config.idReceiver,
      destination: destination,
      type: type,
      payload: payload
    };
  };
};

var EventoServer = new MicroIframe();
var _default = EventoServer;
exports["default"] = _default;