"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "IFrame", {
  enumerable: true,
  get: function get() {
    return _IFrame["default"];
  }
});
Object.defineProperty(exports, "pluginInterceptor", {
  enumerable: true,
  get: function get() {
    return _pluginInterceptor.pluginInterceptor;
  }
});
Object.defineProperty(exports, "isCalledByParent", {
  enumerable: true,
  get: function get() {
    return _caller.isCalledByParent;
  }
});
exports["default"] = void 0;

var _IFrame = _interopRequireDefault(require("./components/IFrame"));

var _pluginInterceptor = require("./hoc/pluginInterceptor");

var _messages = _interopRequireDefault(require("./models/messages"));

var _caller = require("./models/caller");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var _default = _messages["default"];
exports["default"] = _default;