import MicroIframe from '@bif/micro-iframe';
import bifBackgroundImg from 'assets/login_bkg.JPG';
import GlobalForgotView from 'global-forgot-view';
import GlobalLoginView from 'global-login-view';
import GlobalLogoutView from 'global-logout-view';
import GlobalResetView from 'global-reset-view';
import GlobalVerifyView from 'global-verify-view';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { AnimatedSwitch, spring } from 'react-router-transition';
import { RenderAdmin, RenderBuilder, RenderHomeowner, RenderWorkbook } from 'render-apps';
import { routes } from 'route-config';
import styled from 'styled-components';
import './app.css';

const mapStyles = (styles) => ({
  opacity: styles.opacity,
  transform: `scale(${styles.scale})`,
  height: '100%',
  width: '100%',
  position: 'absolute',
});

const bounce = (val) =>
  spring(val, {
    stiffness: 333,
    damping: 42,
  });

const bounceTransition = {
  atEnter: {
    opacity: 0,
    scale: 1,
  },
  atLeave: {
    opacity: bounce(0),
    scale: bounce(0),
  },
  atActive: {
    opacity: bounce(1),
    scale: bounce(1),
    height: 100,
  },
};

class App extends React.Component {
  constructor(props) {
    super(props);
    MicroIframe.init('BIF-MASTER');
  }

  render = () => {
    return (
      <AnimatedSwitch
        atEnter={bounceTransition.atEnter}
        atLeave={bounceTransition.atLeave}
        atActive={bounceTransition.atActive}
        wrapperComponent="section"
        mapStyles={mapStyles}
        className={this.props.className}>
        <Route exact path={routes.LOGIN} component={GlobalLoginView} />
        <Route exact path={routes.VERIFY} component={GlobalVerifyView} />
        <Route path={routes.RESET} component={GlobalResetView} />
        <Route exact path={routes.FORGOT} component={GlobalForgotView} />
        <Route exact path={routes.LOGOUT} component={GlobalLogoutView} />
        <Route path={routes.VIEW_WORKBOOK} component={RenderWorkbook} />
        <Route path={routes.ADMIN} component={RenderAdmin} />
        <Route path={routes.BUILDER} component={RenderBuilder} />
        <Route exact path={routes.HOMEOWNER} component={RenderHomeowner} />
        <Redirect to={routes.LOGIN} />
      </AnimatedSwitch>
    );
  };
}

export default styled(App)`
  min-height: 100%;
  min-width: 100%;
  position: relative;
  overflow: hidden;
  background-image: url(${bifBackgroundImg});
  background-origin: border-box;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  > * {
    position: absolute;
  }
`;
