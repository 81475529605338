import { Card, Typography } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

export const RoomCard = styled((props) => {
  const { room } = props;
  return (
    <Card className={props.className} raised>
      <Typography variant="h6" align={'center'}>{`${room.roomName}`}</Typography>
    </Card>
  );
})`
  display: flex;
  flex-flow: column nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  word-break: break-all;
  padding: 20px;
`;
