import { palette } from '@bif/components';
import { Divider, Typography } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

export default styled(function LoginFooter(props) {
  return (
    <footer className={props.className}>
      <Typography variant="overline">Support</Typography>
      <Divider variant="middle" orientation="vertical" className="divider" />
      <Typography variant="overline">Terms & Conditions</Typography>
    </footer>
  );
})`
  align-items: center;
  background-color: ${palette.black};
  bottom: 0;
  display: flex;
  height: 44px;
  justify-content: flex-start;
  left: 0;
  padding: 12px 40px;
  position: absolute;
  right: 0;
  width: 100%;
  .divider {
    background-color: white;
    border-color: white;
    color: white;
  }
`;
