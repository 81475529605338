export const findProductGroupIndex = (productGroups, categoryId, roomIdentifier) => {
  return productGroups.findIndex((pgd) => {
    const categoryMatch = pgd.categoryId === categoryId;
    const roomMatch = pgd.roomIdentifier === roomIdentifier;
    return [categoryMatch, roomMatch].every((c) => c === true);
  });
};

export const updateProductGroupDetails = (productGroupDetails = [], productGroupDetail) => {
  const nextEntries = [...productGroupDetails];
  const entryIndex = findProductGroupIndex(
    nextEntries,
    productGroupDetail.categoryId,
    productGroupDetail.roomIdentifier
  );

  if (entryIndex > -1) nextEntries[entryIndex] = productGroupDetail;
  else nextEntries.push(productGroupDetail);
  return nextEntries;
};

export const pluckProductGroupsForRoom = (segmentInfoByBaseCategory, roomIdentifier) => {
  const productGroupsByCategory = {};
  for (let [categoryId, { productGroups = [] }] of Object.entries(segmentInfoByBaseCategory)) {
    productGroupsByCategory[categoryId] = [];
    for (let pg of productGroups) {
      if (pg.roomIdentifier === roomIdentifier) {
        productGroupsByCategory[categoryId].push(pg);
      }
    }
  }
  return productGroupsByCategory;
};
