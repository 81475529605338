export const routeHostMap =
  process.env.REACT_APP_API_ENV === 'production'
    ? {
        admin: process.env.REACT_APP_PRODUCTION_ADMIN_HOST,
        builder: process.env.REACT_APP_PRODUCTION_BUILDER_HOST,
        homeowner: process.env.REACT_APP_PRODUCTION_HOMEOWNER_HOST,
      }
    : {
        admin: process.env.REACT_APP_STAGING_ADMIN_HOST,
        builder: process.env.REACT_APP_STAGING_BUILDER_HOST,
        homeowner: process.env.REACT_APP_STAGING_HOMEOWNER_HOST,
      };

export const routes = {
  LOGIN: '/login',
  VERIFY: '/login/verify',
  FORGOT: '/login/forgot',
  RESET: '/login/resetpassword',
  LOGOUT: '/logout',
  ADMIN: '/admin',
  BUILDER: '/builder',
  HOMEOWNER: '/homeowner',
  WORKBOOK: '/homeowner/share-workbook/:token',
  VIEW_WORKBOOK: '/homeowner/share-workbook',
  ADMIN_BUILDERS: '/admin/builders',
};
