import { Dialog, Box } from '@material-ui/core';
import { StyledFormPaper } from '../../styled-components/styled-form-paper';
import React, { Component, createContext } from 'react';
import { Close } from 'styled-icons/material';
import { Icon } from './icons';

export const ModalContext = createContext({
  component: null,
  props: {},
  dialogProps: {},
  showModal: () => {},
  hideModal: () => {},
});

export class GlobalModalProvider extends Component {
  showModal = (component, props = {}, dialogProps = {}) => {
    this.setState({
      component,
      props,
      dialogProps,
    });
  };

  hideModal = () =>
    this.setState({
      component: null,
      props: {},
      dialogProps: {},
    });

  state = {
    component: null,
    props: {},
    dialogProps: {},
    showModal: this.showModal,
    hideModal: this.hideModal,
  };

  render() {
    return <ModalContext.Provider value={this.state}>{this.props.children}</ModalContext.Provider>;
  }
}

export function ModalSingleton() {
  return (
    <ModalContext.Consumer>
      {({ component: Component, props, hideModal, dialogProps }) =>
        Component ? (
          <Dialog
            open={true}
            maxWidth={'md'}
            onClose={hideModal}
            PaperComponent={StyledFormPaper}
            // onBackdropClick={hideModal}
            disableBackdropClick={false}
            disableScrollLock={true}
            fullWidth={!!props.fullWidth}
            {...dialogProps}>
            <Box
              style={{ position: 'absolute', right: 10, top: 10, padding: '5px', zIndex: 99999 }}>
              <Icon
                icon={Close}
                onClick={hideModal}
                button
                disableRipple={false}
                buttonSize={'small'}
              />
            </Box>
            <Component {...props} />
          </Dialog>
        ) : null
      }
    </ModalContext.Consumer>
  );
}
