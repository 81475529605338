export const palette = {
  primary: '#215F44',
  secondary: '#C6CBD4',
  black: '#333333',
  white: '#ffffff',
  tableRowOdd: '#f2f3f5',
  bodyContrast: '#FBFBFB',
  offYellow: '#e6da4f',
  formPaper: '#eeeff1',
  lightBlueGrey: '#c6cbd4',
  brownGrey: '#999999',
  greyBlue: '#71beb8',
  coral: '#FF4C46',
  darkGreen: '#669900',
  builderBannerBackground: '#f8fafc',
  builderMenuBackground: '#f5faf7',
  dashCardTitle: '#ad986d',
  dashCardButtonBorder: '#89a803',
  dashCardButtonStripe: '#fff',
  builderPrimary: '#215F44'
};
