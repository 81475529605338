// @flow
import { FormGroup } from '@material-ui/core';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import type { Address } from 'flowtypes';
import { getCommonFieldProps } from './get-common-field-props';

export const StyledFormGroup = styled(FormGroup)`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
`;

export const AddressSubForm = (props: { address?: Address, disabled: boolean }) => {
  const { address = {}, disabled = false } = props;
  const { control } = useFormContext();
  const commonProps = getCommonFieldProps(control, { disabled, fullWidth: false });
  return (
    <>
      <StyledFormGroup row>
        <Controller
          {...commonProps}
          defaultValue={address.line1 || ''}
          name="address.line1"
          label="Street Address"
          required
        />
        <Controller
          {...commonProps}
          defaultValue={address.line2 || ''}
          name="address.line2"
          label="Street Address 2"
        />
      </StyledFormGroup>
      <StyledFormGroup row>
        <Controller
          {...commonProps}
          defaultValue={address.city || ''}
          name="address.city"
          label="City"
          required
        />
        <Controller
          {...commonProps}
          defaultValue={address.state || ''}
          name="address.state"
          label="State"
          required
        />
        <Controller
          {...commonProps}
          defaultValue={address.zipCode || ''}
          name="address.zipCode"
          label="ZIP Code"
          required
        />
        <Controller
          {...commonProps}
          defaultValue={address.description || ''}
          name="address.description"
          label="Address Description"
        />
      </StyledFormGroup>
    </>
  );
};
