import { Box, Dialog } from '@material-ui/core';
import React from 'react';
import styled, { css } from 'styled-components';
import { bifTheme } from '../shared-styles/bif-mui-theme';
import { StyledFormPaper } from '../styled-components/styled-form-paper';

export const VerticalListStyles = css`
  && {
    display: flex;
    flex-flow: column nowrap;
    > *:not(:last-child) {
      margin-bottom: ${bifTheme.spacing(2)}px;
    }
  }
`;

export const VerticalList = styled(Box)`
  ${VerticalListStyles}
`;

export const HorizontalList = styled(Box)`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-gap: ${bifTheme.spacing(2)}px;
  align-items: center;
`;

export const VertWrapper = styled(Box)`
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: minmax(0, max-content);
  //grid-auto-rows: minmax(min-content, 1fr);
  grid-gap: 10px;
  max-width: 100%;
  justify-items: start;
`;

export const HrztWrapperStyles = css`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;

  @media (min-width: 480px) {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    grid-gap: ${(props) => (props.gap ? props.gap : bifTheme.spacing(3))}px;
    align-items: center;
    justify-content: flex-start;
  }
`;

export const HrztWrapper = styled(Box)`
  ${HrztWrapperStyles}
`;

export const CenteredWrapper = styled(Box)`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  > * {
    margin-bottom: ${bifTheme.spacing(2)}px !important;
  }
  width: 100%;
`;

export const ProjectSectionWrapper = styled(VerticalList)`
  margin-top: 20px;
  align-items: flex-start;
  justify-content: center;
  //padding: 0 20px;
  @media (max-width: 480px) {
    ${HrztWrapper} {
      align-items: flex-start;
      justify-content: space-between;
      > *:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  @media (min-width: 480px) {
    margin-top: 30px;
    //padding: 0 20px;
  }
`;

export const PdfDnDZone = styled(Box)`
  width: 100%;
  border: 1px dashed ${bifTheme.palette.black};
  border-radius: 25px;
  padding: 10px;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledDialogCss = css`
  .dialog-title {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 0;
  }

  .centered-dialog-content {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
  }

  .submit-button {
    margin-top: ${bifTheme.spacing(3)}px;
    margin-bottom: ${bifTheme.spacing(3)}px;
  }

  .form-spacer {
    margin-top: ${bifTheme.spacing(3)}px;
    margin-bottom: ${bifTheme.spacing(3)}px;
    &.header {
      margin-bottom: ${bifTheme.spacing(1)}px;
    }
  }
`;

export const StyledDialog = styled(Dialog).attrs((props) => ({
  disableBackdropClick: true,
  PaperComponent: StyledFormPaper,
  disableScrollLock: true,
  maxWidth: 'xs',
  ...props,
}))`
  ${StyledDialogCss}
`;
