import axios from 'axios';

const baseURL =
  process.env.REACT_APP_API_ENV === 'production'
    ? process.env.REACT_APP_PROD_API_ENDPOINT
    : process.env.REACT_APP_QA_API_ENDPOINT;

const authApi = axios.create({
  baseURL,
  responseType: 'json',
  timeout: 7500,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export const setAuthHeaders = (token) =>
  (authApi.defaults.headers.common['Authorization'] = `BIF ${token}`);
export const clearAuthHeaders = () => delete authApi.defaults.headers.common['Authorization'];

export const getAuthUser = async (token) => {
  const { data: self } = await authApi.get('/user/selfheader');
  const { data: companies } = await authApi.get(`/Company/ForUser?userId=${self.id}`);

  return {
    token,
    companies,
    ...self,
  };
};

export const getPropsForMicroFrontend = async (token) => {
  setAuthHeaders(token);
  return await getAuthUser(token);
};

export default authApi;
