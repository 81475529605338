import { AppBar } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { palette } from '../shared-styles/palette';

export const StyledAppBar = styled(({ backgroundColor, ...rest }) => <AppBar {...rest} />)`
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : palette.black} !important;
  overflow: auto;
  height: min-content;
  width: 100%;
  padding: 0 5px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  z-index: 999;
  && .MuiTab-root {
    min-width: 42px;
  }

  .toolbar {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    flex-flow: row nowrap;
    @media (max-width: 480px) {
      flex-flow: column nowrap;
    }
    .toolbar-tabs {
      margin: 0 25px;
      flex-grow: 1;
      justify-content: flex-start;
    }
  }
`;
