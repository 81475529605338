// @flow
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextField as MUITextField,
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import _ from 'lodash-es';
import isArray from 'lodash-es/isArray';
import React from 'react';
import styled from 'styled-components';
import { palette } from '../../shared-styles/palette';
import NumberFormat from 'react-number-format';

const noop = _.noop;

type SelectOptionShape = Array<[string, string]>; // array of tuples: [optKey, optVal]

export const StyledTextField = ({ errors, ...props }) => {
  const computedProps = _.isEmpty(errors)
    ? props
    : {
        ...props,
        error: true,
        helperText: errors[props.name]?.message,
      };
  return (
    <MUITextField margin="dense" size="small" variant="outlined" fullWidth {...computedProps} />
  );
};

export const CheckboxField = (props) => {
  const { errors = {}, fieldKey, initValue = [], label, onChange, options = [] } = props;

  const stringValues = isArray(initValue) ? initValue.map((v) => '' + v) : [initValue];
  const [checked, setChecked] = React.useState(stringValues);
  return (
    <FormControl error={errors[fieldKey]} margin="normal">
      <FormLabel component="legend">{label}</FormLabel>
      <FormGroup>
        {options.map(([optKey, optLabel]) => (
          <FormControlLabel
            key={`radio-input-${optKey}`}
            onChange={({ target: { checked: isChecked } }) => {
              const nextValue = isChecked
                ? [...checked, optKey]
                : checked.filter((opt) => opt !== optKey);
              onChange(nextValue, fieldKey);
              setChecked(nextValue);
            }}
            control={<Checkbox checked={checked.includes(optKey)} name={fieldKey} value={optKey} />}
            label={optLabel}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
};

export const SwitchField = (props) => {
  const { fieldKey, label, onChange, errors = {}, initValue = false } = props;
  const [switchState, setSwitchState] = React.useState(initValue);
  return (
    <FormGroup className={props.className} error={errors[fieldKey]} margin="normal" row>
      <FormControlLabel
        label={label}
        control={
          <Switch
            color="primary"
            checked={switchState}
            onChange={() => {
              const nextState = !switchState;
              setSwitchState(nextState);
              onChange(nextState, fieldKey);
            }}
            value={switchState}
          />
        }
      />
    </FormGroup>
  );
};

export function RadioField(props) {
  const { initValue = '', label, onChange, options = [], required, fieldKey } = props;
  const [selectedVal, selectNewVal] = React.useState(initValue);
  return (
    <FormControl margin="normal" required={required}>
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup
        value={selectedVal}
        onChange={({ target: { value: nextValue } }) => {
          onChange(nextValue, fieldKey);
          selectNewVal(nextValue);
        }}>
        {options.map(([optionID, optionLabel]) => (
          <FormControlLabel
            key={`radio-field-${optionID}`}
            value={optionID + ''}
            control={<Radio color="secondary" />}
            label={optionLabel}
            labelPlacement="end"
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

export const DateField = (props) => {
  const {
    fieldKey,
    label,
    required,
    errors = {},
    onChange,
    initValue = null,
    margin = 'normal',
    ...rest
  } = props;
  const [dateValue, setDateValue] = React.useState(initValue);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        name={fieldKey}
        autoOk
        disableToolbar
        format="MM/dd/yyyy"
        margin={margin}
        label={label}
        error={errors[fieldKey]}
        required={required}
        value={dateValue}
        variant="inline"
        onChange={(date) => {
          setDateValue(date);
          onChange(date, fieldKey); // TODO, switch arguments to follow sig of other fields, refactor where its used
        }}
        {...rest}
      />
    </MuiPickersUtilsProvider>
  );
};

export const RowSelectField = (props: {
  disabled?: Boolean,
  error?: string,
  fieldKey: string,
  fullWidth?: Boolean,
  initValue?: string,
  label: string,
  margin?: string,
  mulitple?: Boolean,
  onChange: (string, any) => void,
  options: SelectOptionShape,
  onItemClick: (any) => void,
  placeholder: string,
  required: Boolean,
  variant?: string,
  style?: any,
}) => {
  let {
    error,
    fieldKey,
    initValue,
    label,
    margin = 'dense',
    multiple = false,
    disabled = false,
    onChange,
    onItemClicked = noop,
    options = [],
    required,
    variant = 'outlined',
    fullWidth = false,
    style,
  } = props;

  if (!initValue) initValue = multiple ? [] : '';
  const [selectState, setSelectState] = React.useState(initValue);
  const onSelect = (selectedValue) => {
    setSelectState(selectedValue);
    onChange(selectedValue, fieldKey);
  };
  const selectableOptions = React.useMemo(() => {
    return options.map(([optKey, optLabel]) => (
      <MenuItem key={optKey} value={optKey} onClick={onItemClicked}>
        {optLabel}
      </MenuItem>
    ));
  }, [options]);

  return (
    <FormControl
      margin={margin}
      required={required}
      fullWidth={fullWidth}
      error={!!error}
      variant={variant}>
      {label && <InputLabel>{label}</InputLabel>}
      <Select
        style={style}
        multiple={multiple}
        variant={variant}
        disabled={disabled}
        label={label}
        value={selectState}
        onChange={({ target: { value } }) => onSelect(value)}>
        {selectableOptions}
      </Select>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

function findOptionLabel(options: SelectOptionShape, optionKey: string) {
  return options.find(([optKey]) => optKey === optionKey)[1];
}

export const InputField = styled.input`
  height: 44px;
  width: 384px;
  background-color: ${palette.white};
  border-radius: 4px;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.087);
  border: 1px solid rgb(198, 203, 212);
`;

export const PhoneNumberField = (props) => {
  const {
    error,
    fieldKey,
    initValue = '',
    label = 'Phone number',
    onChange,
    required,
    ...rest
  } = props;
  const [value, setValue] = React.useState(initValue);

  return (
    <MUITextField
      label={label}
      margin="dense"
      size="small"
      variant="outlined"
      required={required}
      value={value}
      onChange={({ target: { value: next } }) => {
        setValue(next);
        onChange(next, fieldKey); // note: in glazd backend phone number must be passed as string
      }}
      error={!!error}
      helperText={error}
      InputProps={{
        inputComponent: PhoneNumberFormatter,
      }}
      {...rest}
    />
  );
};

function PhoneNumberFormatter(props) {
  const { inputRef, onChange, ...rest } = props;

  return (
    <NumberFormat
      getInputRef={inputRef}
      format="+1 (###) ###-####"
      mask="_"
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      {...rest}
    />
  );
}

export function CurrencyField({ value, ...props }) {
  return (
    <StyledTextField value={value} InputProps={{ inputComponent: CurrencyFormatter }} {...props} />
  );
}

function CurrencyFormatter(props) {
  const { inputRef, onChange, ...rest } = props;
  return (
    <NumberFormat
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange(values.value);
      }}
      isNumericString
      thousandSeparator
      prefix="$"
      {...rest}
    />
  );
}
