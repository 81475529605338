"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ParentCallerUrlParam = exports.MicroIframeTypes = exports.ID_MICROIFRAME_MSGS = void 0;
var MicroIframeTypes = {
  LOADED: 'loaded',
  RELOAD: 'reload',
  CHANGE_HASH: 'change_hash',
  FOCUS: 'focus',
  GOTO_EXTERNAL_HASH: 'goto_external_hash'
};
exports.MicroIframeTypes = MicroIframeTypes;
var ParentCallerUrlParam = 'parentCaller';
exports.ParentCallerUrlParam = ParentCallerUrlParam;
var ID_MICROIFRAME_MSGS = 'MICRO-IFRAME-MSGS';
exports.ID_MICROIFRAME_MSGS = ID_MICROIFRAME_MSGS;