import { Box, LinearProgress, Typography } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { Close } from 'styled-icons/material';
import { palette } from '../../shared-styles/palette';
import { formatIsoDate } from '../../shared-utils/date-fns-utils';
import { VerticalList, VertWrapper } from '../../template-components/box-templates';
import { Icon } from '../../functional-components/atomic-components/icons';
import { PrimaryButton } from '../atomic-components/buttons';
import { StyledTextField } from '../atomic-components/fields';

const Comment = styled(Box)`
  padding-top: 15px;
  padding-bottom: 15px;
  .comment {
    display: flex;
    flex-flow: row wrap;
    background-color: ${palette.formPaper};
    width: 300px;
    min-height: 44px;
    padding: 10px;
    justify-items: center;
    word-break: break-all;
  }

  .comment-meta {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    > * {
      margin-left: 10px;
    }
  }
`;

const emptyPromise = async () => {};
export const SharedCommentList = styled((props) => {
  const {
    title = '',
    onSubmitComment = emptyPromise,
    onDeleteComment = emptyPromise,
    comments = [],
    isLoading = false,
  } = props;

  const [comment, setComment] = React.useState('');
  const [submitting, setSubmitting] = React.useState(false);
  const onSubmit = () => {
    setSubmitting(true);
    onSubmitComment(comment)
      .then(() => {
        setComment('');
      })
      .finally(() => {
        setSubmitting(false);
      });
  };
  const onDelete = (commentId) => {
    setSubmitting(true);
    onDeleteComment(commentId).finally(() => {
      setSubmitting(false);
    });
  };
  const isRemote = isLoading || submitting;
  return (
    <VerticalList className={props.className}>
      <Box className="header">
        <Typography variant="h3">{`${title} Comments`}</Typography>
        {isRemote && <LinearProgress color={'primary'} />}
      </Box>
      <StyledTextField
        disabled={submitting}
        onChange={({ target: { value } }) => setComment(value)}
        fullWidth
        value={comment}
        multiline={true}
        autoFocus={true}
        color="primary"
        rows="3"
        label="Type here"
        rowsMax="6"
      />
      <PrimaryButton onClick={onSubmit} disabled={submitting || comment.length < 1}>
        Add Note
      </PrimaryButton>
      <VertWrapper>
        {comments.map((c, i) => {
          const { text, timestamp, userName, id } = c;
          return (
            <Comment key={`company-comment-${i}`}>
              <Box className="comment">
                <Typography>{text}</Typography>
              </Box>
              <Box className="comment-meta">
                <Typography variant={'caption'}>{userName}</Typography>
                <Typography variant={'caption'}>{formatIsoDate(timestamp)}</Typography>
                <Icon
                  button={true}
                  size={16}
                  onClick={() => onDelete(id)}
                  icon={Close}
                  buttonSize={'small'}
                />
              </Box>
            </Comment>
          );
        })}
      </VertWrapper>
    </VerticalList>
  );
})`
  width: 100%;
  .header {
    width: 100%;
  }
  align-items: flex-end;
  padding: 20px;
  min-width: 300px;
  justify-content: flex-start;
`;
