import { VerticalListStyles } from '@bif/components';
import { Box } from '@material-ui/core';
import styled from 'styled-components';

export default styled(Box)`
  align-content: center;
  align-items: center;
  background: transparent;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  min-height: 100%;
  min-width: 100%;
`;

export const StyledForm = styled.form`
  ${VerticalListStyles}
`;
