// @flow
import { IconButton } from '@material-ui/core';
import { useSnackbar, withSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { CloseCircle } from 'styled-icons/remix-line';

export type SnackProps = {
  key?: number, // don't pass with enqueueSnack action
  message: string,
  timeout?: number,
  variant: 'default' | 'success' | 'error' | 'warning' | 'info',
  // archive: boolean
};

const closeButtonStyle = {
  marginRight: '5px',
};

function SnackNotifier(props: { snacks: Array<SnackProps> }) {
  const { snacks } = props;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [snackArchive, archiveSnack] = useState([]);
  const eatSnack = (snackKey) => closeSnackbar(snackKey);

  useEffect(() => {
    snacks.forEach(
      (snack) => {
        const { variant, message, timeout, key } = snack;
        const persist = true;
        if (!snackArchive.includes(key)) {
          if (timeout) {
            enqueueSnackbar(message, { variant, key, persist });
            setTimeout(() => eatSnack(key), timeout);
          } else {
            enqueueSnackbar(
              [
                message,
                <IconButton
                  key={key}
                  color="inherit"
                  style={{ marginLeft: '3px' }}
                  onClick={() => eatSnack(key)}>
                  <CloseCircle style={closeButtonStyle} />
                </IconButton>,
              ],
              { variant, key, persist }
            );
          }
          archiveSnack([...snackArchive, key]);
        }
      },
      [snacks]
    );

    // return () => closeSnackbar();
  });

  return null;
}

export const snackbar = withSnackbar(SnackNotifier);
