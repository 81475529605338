import { bifTheme } from '../shared-styles/bif-mui-theme';
import { palette } from '../shared-styles/palette';
import styled from 'styled-components';
import { Paper } from '@material-ui/core';

export const StyledFormPaper = styled(Paper)`
  background-color: ${palette.formPaper} !important;
  &&& .MuiTextField-root {
    background-color ${palette.white} ;
  }
  padding: 0 ${bifTheme.spacing(3)}px;
`;
