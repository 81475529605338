import { Card } from '@material-ui/core';
import styled from 'styled-components';
import { VerticalListStyles } from '../template-components/box-templates';

export const StyledCategoryCard = styled(Card).attrs((props) => ({ raised: true, ...props }))`
  margin: 20px 10px;
  flex: 1 1 250px;

  .category-card-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    .justify-right {
      ${VerticalListStyles};
      justify-content: space-evenly;
      justify-self: flex-end;
      align-items: flex-end;
    }
  }
`;
