import ImageHotspots from '@bif/hotspots';
import { Box, Grid } from '@material-ui/core';
import _ from 'lodash-es';
import React from 'react';
import styled from 'styled-components';
import { pluckProductGroupsForRoom } from '../../shared-utils/product-group-utils';
import { VerticalListStyles } from '../../template-components/box-templates';
import { RoomCard } from '../blueprint-components/room-card';
import { RoomHotspot } from './room-hotspot';

export const RenderBlueprintFloor = styled((props) => {
  const { floor, onClickRoom = _.noop, categories, segmentInfo } = props;

  let renderComp;
  if (floor?.blueprintAssetId) {
    renderComp = (
      <ImageHotspots
        src={`https://assets.builditfab.com/${floor?.blueprintAssetId}`}
        hideFullscreenControl={false}
        hideZoomControls={false}
        hideMinimap={false}
        onClick={_.noop}
        hotspots={
          floor?.rooms?.map((room) => {
            const productGroupsForRoom = pluckProductGroupsForRoom(
              segmentInfo,
              room.roomIdentifier
            );
            return {
              x: room.coordinates.x,
              y: room.coordinates.y,
              content: (
                <RoomHotspot
                  room={room}
                  onClickRoom={onClickRoom}
                  categories={categories}
                  productGroups={productGroupsForRoom}
                />
              ),
            };
          }) ?? []
        }
      />
    );
  } else {
    renderComp = (
      <Box className={'no-blueprint-main'}>
        <Grid container spacing={3}>
          {floor?.rooms?.map((r) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={`${r.roomName}+${r.roomIdentifier}`}>
              <RoomCard room={r} onClickRoom={onClickRoom} />
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  }

  return <Box className={props.className}>{renderComp}</Box>;
})`
  ${VerticalListStyles};
  width: 100%;
  height: calc(100vh - 68px);
  align-items: flex-start;

  .no-blueprint-main {
    padding-top: 15px;
    height: 100%;
    width: 100%;
    ${VerticalListStyles}
    .add-room-btn {
      align-self: flex-end !important;
      margin: 10px;
    }
  }
`;
