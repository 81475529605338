import MicroIframe, { IFrame } from '@bif/micro-iframe';
import _ from 'lodash-es';
import { useUserManager } from 'master-hooks';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { routeHostMap, routes } from 'route-config';

export function RenderAdmin() {
  const { self, history } = useUserManager();
  if (!self) return <Redirect to={routes.LOGIN} />;

  const onLoad = () =>
    MicroIframe.postMessageToIframes('SELF', {
      self,
      goTo: sessionStorage.getItem('GO_TO_ADMIN_ROUTE'),
    });
  const onReceive = (message) => {
    const { type, payload } = message;
    if (type === 'LOG_OUT') history.push(routes.LOGOUT);
    if (type === 'GO_TO_COMPANY') {
      const { company } = payload;
      sessionStorage.setItem('bif-admin-current-company', JSON.stringify({ company }));
      sessionStorage.removeItem('bif-builder-project');
      history.push(routes.BUILDER);
    }
    if (type === 'GO_TO_COMPANY_PROJECT') {
      const { company, projectId } = payload;
      sessionStorage.setItem('bif-admin-current-company', JSON.stringify({ company }));
      sessionStorage.setItem('bif-builder-project', JSON.stringify({ projectId }));
      history.push(routes.BUILDER);
    }
  };

  return <IFrame src={routeHostMap.admin} onReceiveMessage={onReceive} onIframeLoaded={onLoad} />;
}

export function RenderBuilder() {
  const { self, history } = useUserManager();
  if (!self) return <Redirect to={routes.LOGIN} />;

  const companyInSession = sessionStorage.getItem('bif-admin-current-company');
  const projectInfo = sessionStorage.getItem('bif-builder-project');

  let onLoad;
  if (companyInSession) {
    // companyInSession means we're an admin user
    const { company } = JSON.parse(companyInSession);
    const { projectId = false } = projectInfo ? JSON.parse(projectInfo) : {};
    if (projectId)
      onLoad = () =>
        MicroIframe.postMessageToIframes('GO_TO_PROJECT', { self, company, projectId });
    else onLoad = () => MicroIframe.postMessageToIframes('ADMIN_SELF', { self, company });
  } else onLoad = () => MicroIframe.postMessageToIframes('SELF', self);

  const onReceive = (message) => {
    const { type, payload } = message;
    if (type === 'LOG_OUT') history.push(routes.LOGOUT);
    if (type === 'GO_TO_ADMIN') {
      sessionStorage.setItem('GO_TO_ADMIN_ROUTE', payload);
      sessionStorage.removeItem('bif-admin-current-company');
      history.push(routes.ADMIN);
    }

    if (type === 'SELECT_PROJECT') {
      const { projectId } = payload;
      sessionStorage.setItem('bif-builder-project', JSON.stringify({ projectId }));
    }

    if (type === 'GO_TO_WORKBOOK') {
      sessionStorage.setItem('bif-admin-view-project', JSON.stringify(payload.project));
      history.push(routes.VIEW_WORKBOOK);
    }

    if (type === 'SIMULATE_HOMEOWNER') {
      console.log('sim homeowner payload', payload);
      const projectClient = payload?.projectClient[0];
      localStorage.setItem(
        'bif-homeowner-simulator',
        JSON.stringify({
          ...projectClient,
          token: self.token,
          isSimulating: true,
        })
      );
      history.push(routes.HOMEOWNER);
    }
  };

  return <IFrame src={routeHostMap.builder} onReceiveMessage={onReceive} onIframeLoaded={onLoad} />;
}

export function RenderHomeowner() {
  const { self, history } = useUserManager();
  if (!self) return <Redirect to={routes.LOGIN} />;
  const onLoad = () => MicroIframe.postMessageToIframes('SELF', self);
  const onReceive = (message) => {
    const { type, payload } = message;
    console.log("message",message);
    const projectClient = payload?.projectClient
    if (type === 'LOG_OUT') history.push(routes.LOGOUT);
    if (type === 'STOP_SIMULATING') {
      localStorage.removeItem('bif-homeowner-simulator');
      history.push(routes.BUILDER);
    }
    if (type === 'SIMULATE_BUILDER') {
      console.log('sim builder payload', payload);
      const projectClient = payload?.project;
      localStorage.setItem(
          'bif-builder-simulator',
          JSON.stringify({
            ...projectClient,
            token: self.token,
            isSimulating: true,
          })
      );
      history.push(routes.BUILDER);
    }
  };

  return (
    <IFrame src={routeHostMap.homeowner} onReceiveMessage={onReceive} onIframeLoaded={onLoad} />
  );
}

export function RenderWorkbook() {
  const { self, history } = useUserManager();
  const [loaded, setLoaded] = React.useState(false);
  const onLoad = () => setLoaded(true);
  const onReceive = _.noop;
  const bifadminviewproject = sessionStorage.getItem('bif-admin-view-project');

  React.useEffect(() => {
    if (self !== null && loaded === true) {
      if (bifadminviewproject) {
        MicroIframe.postMessageToIframes('ADMIN', {
          self,
          project: JSON.parse(bifadminviewproject),
        });
      } else {
        MicroIframe.postMessageToIframes('SHARED', { self });
      }
    }
  }, [self, loaded, bifadminviewproject]);

  // todo: add UI for handling bad token case
  return (
    <IFrame src={routeHostMap.homeowner} onReceiveMessage={onReceive} onIframeLoaded={onLoad} />
  );
}
