import { Box, Divider, IconButton, Tooltip, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { PlusCircle } from 'styled-icons/boxicons-solid';
import { palette } from '../../shared-styles/palette';
import { HrztWrapper, VertWrapper } from '../../template-components/box-templates';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    padding: 15,
    border: `3px solid ${palette.primary}`,
    minWidth: 'max-content',
    borderRadius: '15px',
    maxHeight: '400px',
    overflowY: 'auto',
    pointerEvents: 'auto',
  },
}))(Tooltip);

const StyledHotspotIcon = styled(PlusCircle).attrs((props) => ({
  size: 35,
  ...props,
}))`
  color: ${palette.primary};
  opacity: 0.9;
`;

export const HotspotDetail = styled(({ room, productGroups, categories, className }) => {
  const renderProductSelections = React.useMemo(() => {
    return Object.entries(productGroups).map(([k, v = []]) => {
      const category = categories.find((c) => c.id === k);
      const name = category?.name ?? '';
      const baseCategoryRequiredProductCount = v.reduce(
        (acc, { requiredQuantity }) => acc + requiredQuantity,
        0
      );
      return (
        <Box key={`${room.roomIdentifier}-${k}`}>
          <Divider variant="middle" light={false} />
          <HrztWrapper gap={3}>
            <Typography>{name}</Typography>
            <Typography variant="body2">{baseCategoryRequiredProductCount}</Typography>
          </HrztWrapper>
        </Box>
      );
    });
  }, [productGroups, room]);

  return (
    <Box className={className}>
      <Typography className={'room-name'}>{room.roomName}</Typography>
      {room?.roomNick && <Typography variant={'subtitle2'}>{room.roomNick}</Typography>}
      <VertWrapper gap={5}>{renderProductSelections}</VertWrapper>
    </Box>
  );
})`
  min-width: 4rem;
  .room-name {
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0;
    text-transform: uppercase;
    color: ${palette.black};
    margin-bottom: 2.5px;
  }
`;

export const RoomHotspot = ({ room, onClickRoom, productGroups, categories }) => {
  const TIMEOUT = 2000;
  const [timer, setTimer] = useState();
  const [open, setOpen] = useState(false);

  function clearTimer() {
    clearTimeout(timer);
    setTimer();
  }

  function startTimer(timerFn, timing = TIMEOUT) {
    const newFn = () => {
      timerFn();
      clearTimer();
    };
    setTimer(setTimeout(newFn, timing));
  }

  useEffect(() => {
    if (open) {
      startTimer(() => setOpen(false));
    }
    return clearTimer;
  }, [open, setOpen]);

  return (
    <LightTooltip
      PopperProps={{
        onMouseEnter: clearTimer,
        onMouseLeave: () => setOpen(false),
      }}
      open={open}
      enterDelay={250}
      leaveDelay={250}
      placement={'top-end'}
      disableFocusListener={false}
      disableTouchListener={false}
      title={<HotspotDetail room={room} productGroups={productGroups} categories={categories} />}>
      <IconButton onClick={() => onClickRoom(room)} onMouseEnter={() => setOpen(true)}>
        <StyledHotspotIcon />
      </IconButton>
    </LightTooltip>
  );
};
