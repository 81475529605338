import { bifTheme, GlobalStyle } from '@bif/components';
import { MuiThemeProvider } from '@material-ui/core';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './app';
import * as serviceWorker from './serviceWorker';

/*
 * note: The difference between `isProd` and `isProdEnv` is that `isProd` flags
 * if the code is coming from a build directory, which could be running on Production,
 * Staging, or Local environments. `isProdEnv` indicates if the code is running on
 * the actual production website: app.builditfab.com. If isProdEnv is true, isProd
 * will always be true. They will be both be false during local development using yarn start
 * */

ReactDOM.render(
  <>
    <GlobalStyle />
    <MuiThemeProvider theme={bifTheme}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </MuiThemeProvider>
  </>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
