import { Box, Typography } from '@material-ui/core';
import _ from 'lodash-es';
import React from 'react';
import styled from 'styled-components';

export const StyledAttributeText = styled(
  /**
   * @return {null}
   */
  function StyledAttributeText(props) {
    const { label, description } = props;
    if (_.isEmpty(description)) return null;
    return (
      <Box className={props.className}>
        <Typography className={'label'}>{label}: </Typography>
        <Typography variant={'body2'}>{description}</Typography>
      </Box>
    );
  }
)`
  display: flex;
  flex-flow: row nowrap;
  align-items: baseline;
  max-width: 100%;
  max-height: 130px;
  overflow-y: auto;
  .label {
    margin-right: 5px;
  }
`;
