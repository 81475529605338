import { Tab, Tabs, withStyles } from '@material-ui/core';
import React from 'react';

export const StyledTabs = withStyles((theme) => ({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > div': {
      // maxWidth: 80,
      width: '100%',
      backgroundColor: theme.palette.primary.main,
    },
  },
}))((props) => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

export const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'UPPERCASE',
    color: '#fff',
    fontFamily: "Roboto-Thin', 'Roboto Thin', 'Roboto', sans-serif",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(16),
    marginRight: theme.spacing(1),
    '&:focus': {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />);
