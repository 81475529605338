import { getAuthUser, setAuthHeaders } from 'api';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

export const useUserManager = () => {
  const history = useHistory();

  // if admin or builder is simulating homeowner, setup the simulation data again
  const simulatedSelf = localStorage.getItem('bif-homeowner-simulator');
  // note: user is cached in session storage by global-login-view
  const sessionSelf = sessionStorage.getItem('bif-user');
  let storedUser = null;
  try {
    storedUser = JSON.parse(simulatedSelf ?? sessionSelf);
  } catch (err) {
    console.error(err);
  }

  const [self, setSelf] = React.useState(storedUser);

  // note: this is the logic to "login" a tokenized user for the shared workbook in bif-homeowner
  const { token = null } = useParams();
  React.useEffect(() => {
    if (token) {
      const decodedToken = window.decodeURIComponent(token);
      setAuthHeaders(decodedToken);
      getAuthUser(decodedToken).then((user) => setSelf(user));
    }
  }, [token]);

  return {
    self,
    history,
  };
};
