import React from 'react';

export default class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true });

    if (this.props.onError) this.props.onError();
  }

  render() {
    const { className } = this.props;
    if (this.state.hasError) {
      return <div className={className}>An Error Has Occurred.</div>;
    }
    return this.props.children;
  }
}
