// @flow
import {
  Button as MuiButton,
  Button,
  ClickAwayListener,
  Grid,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  ButtonGroup,
} from '@material-ui/core';
import * as React from 'react';
import styled from 'styled-components';
import { ArrowDropDown } from 'styled-icons/material';
import { palette } from '../../shared-styles/palette';

export const PrimaryButton = styled(MuiButton).attrs((props) => ({
  variant: 'contained',
  color: 'primary',
  size: 'large',
  ...props,
}))`
  //padding: 6px 0;
  height: 28px;
  border-radius: 20px !important;
  width: max-content;
`;

export const BuilderPrimaryButton = styled(MuiButton).attrs((props) => ({
  variant: 'contained',
  color: 'default',
  size: 'large',
  ...props,
}))`
  && {
    font-family: 'Roboto-Thin', 'Roboto Thin', 'Roboto', sans-serif;
    font-size: 1rem;
    color: ${palette.white};
    height: 48px;
    width: max-content;
    background-color: ${palette.builderPrimary};
    border: 0px solid ${palette.dashCardButtonBorder};
  }
`;

export const SecondaryButton = styled(MuiButton).attrs((props) => ({
  variant: 'contained',
  color: 'secondary',
  size: 'large',
  ...props,
}))`
  height: 28px;
  border-radius: 20px !important;
  width: max-content;
`;

export const BodyButton = styled(MuiButton).attrs((props) => ({
  variant: 'contained',
  color: 'inherit',
  size: 'large',
  ...props,
}))`
  padding: 6px 0;
  height: 36px;
  border-radius: 24px !important;
  background-color: ${palette.black} !important;
  color: ${palette.white} !important;
`;

export const OptionButton = (props) => {
  const { options = [], label, onClick, color = 'primary', variant = 'contained' } = props;
  const [open, setOpen] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const anchorRef = React.useRef(null);
  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };
  const handleToggle = () => setOpen((prevOpen) => !prevOpen);
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) return;
    setOpen(false);
  };

  return (
    <>
      <Button color={color} variant={variant} onClick={handleToggle} ref={anchorRef}>
        {label} <ArrowDropDown height="20px" />
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        style={{ zIndex: 9999 }}
        disablePortal>
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'end bottom',
            }}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}>
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

type ButtonOptionCB = (SyntheticEvent<HTMLButtonElement>) => void;
type ButtonOption = [ButtonOptionCB, React.Node]; // callback, label

export const SplitButton = (props: { buttonOptions: Array<ButtonOption> }) => {
  const { buttonOptions, ...rest } = props;
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => setOpen((prevOpen) => !prevOpen);
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const [primaryBtnCb, primaryBtnLabel, primaryButtonProps] = React.useMemo(
    () => buttonOptions.shift(),
    [buttonOptions]
  );

  return (
    <Grid container direction="column" alignItems="center">
      <Grid item xs={12}>
        <ButtonGroup variant="contained" color="primary" {...rest}>
          <Button onClick={primaryBtnCb} size={'small'} {...primaryButtonProps}>
            {primaryBtnLabel}
          </Button>
          <Button
            color="primary"
            style={{ maxWidth: '40px' }}
            size="small"
            onClick={handleToggle}
            ref={anchorRef}
            {...rest}>
            <ArrowDropDown />
          </Button>
        </ButtonGroup>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          placement={'bottom-end'}>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'right top' : 'right bottom',
              }}>
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList disablePadding>
                    {buttonOptions.map(([cb, label, menuItemProps], index) => (
                      <MenuItem
                        divider
                        key={`${index}+split-btn-lbl`}
                        onClick={(event) => cb(event, index)}
                        dense
                        {...menuItemProps}>
                        {label}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    </Grid>
  );
};
