// @flow
import {
  HorizontalList,
  Icon,
  MasterFormTemplate,
  PrimaryButton,
  StyledTextField,
} from '@bif/components';
import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  InputAdornment,
  Typography,
} from '@material-ui/core';
import authApi, { clearAuthHeaders, getPropsForMicroFrontend, setAuthHeaders } from 'api';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, Redirect } from 'react-router-dom';
import { routes } from 'route-config';
import { Visibility, VisibilityOff } from 'styled-icons/material-outlined';
import type { UserHeader } from 'flowtypes';
import LoginFooter from './login-footer';
import StyledGlobalLoginView, { StyledForm } from './styled-global-login-view';

const storage = window.localStorage;
const storageKey = 'bif-remember-me';

const authenticate = async (username: string, password: string, rememberMe: boolean) => {
  const {
    data: { value: authToken },
  } = await authApi({
    method: 'post',
    url: '/auth',
    data: { username, secret: password },
  });
  setAuthHeaders(authToken);
  if (rememberMe) localStorage.setItem(storageKey, JSON.stringify(authToken));
  else localStorage.removeItem(storageKey);

  return authToken;
};

export default function GlobalLoginView() {
  const storedUser = sessionStorage.getItem('bif-user');
  const [currentUser, setCurrentUser] = React.useState(storedUser ? JSON.parse(storedUser) : null);
  const [loginError, setLoginError] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const { handleSubmit, control } = useForm({
    defaultValues: {
      username: '',
      password: '',
    },
  });

  const storedToken = storage.getItem(storageKey);
  const [memoryChecked, setMemoryChecked] = React.useState(false);
  const [remember, setRemember] = React.useState(!!storedToken);
  const setSessionUser = async (self: UserHeader) => {
    sessionStorage.setItem('bif-user', JSON.stringify(self));
    setCurrentUser(self);
  };

  const handleLogIn = async ({ username, password }) => {
    setShowPassword(false);
    if (loginError) setLoginError(false);
    try {
      const token = await authenticate(username, password, remember);
      const self = await getPropsForMicroFrontend(token);
      await setSessionUser(self);
    } catch (error) {
      setLoginError(true);
      console.info(error);
    }
  };

  const handleStoredToken = async (token) => {
    const self = await getPropsForMicroFrontend(token);
    await setSessionUser(self);
  };

  React.useEffect(() => {
    if (storedToken && !currentUser) {
      handleStoredToken(storedToken)
        .then(() => setMemoryChecked(true))
        .catch((err) => {
          console.error(err);
          clearAuthHeaders();
          storage.clear();
          setMemoryChecked(true);
        });
    } else {
      setMemoryChecked(true);
    }
  }, [storedToken]);

  if (memoryChecked && currentUser) {
    if (currentUser.isAdminUser) {
      return <Redirect to={routes.ADMIN} />;
    } else if (currentUser.companies?.length > 0) {
      return <Redirect to={routes.BUILDER} />;
    } else {
      return <Redirect to={routes.HOMEOWNER} />;
    }
  }

  return (
    <StyledGlobalLoginView>
      {!!memoryChecked && (
        <MasterFormTemplate>
          <StyledForm onSubmit={handleSubmit(handleLogIn)} className={'login-form'}>
            <Typography variant={'subtitle2'} align={'center'}>
              Making fabulous selections just got faster, easier, and a whole lot more fun.
            </Typography>
            <Controller
              as={StyledTextField}
              autoComplete="email"
              autoFocus={true}
              error={!!loginError}
              control={control}
              fullWidth={true}
              label="Email"
              name="username"
              required={true}
              type="email"
              variant="filled"
            />
            <Controller
              as={StyledTextField}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Icon
                      button
                      buttonSize={'small'}
                      color={'secondary'}
                      icon={showPassword ? Visibility : VisibilityOff}
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={(e) => e.preventDefault()}
                    />
                  </InputAdornment>
                ),
              }}
              autoComplete="password"
              control={control}
              error={!!loginError}
              fullWidth={true}
              label="Password"
              name="password"
              required={true}
              type={showPassword ? 'text' : 'password'}
              variant="filled"
            />
            {!!loginError && (
              <FormHelperText error>
                We don’t recognize that email. Please use the email you received in the invitation
                from Build It Fab.
              </FormHelperText>
            )}
            <HorizontalList style={{ width: '100%', justifyContent: 'space-between' }}>
              <FormControlLabel
                control={<Checkbox checked={remember} color="primary" />}
                label="Remember Me"
                labelPlacement="end"
                onChange={({ target: { checked } }) => setRemember(checked)}
                className="left-margin-fix"
              />
              <Link to={routes.FORGOT}>
                <Typography variant="overline">Forgot Password</Typography>
              </Link>
            </HorizontalList>
            <PrimaryButton type="submit" fullWidth>
              Log In
            </PrimaryButton>
          </StyledForm>
        </MasterFormTemplate>
      )}
      <LoginFooter />
    </StyledGlobalLoginView>
  );
}
