import React from 'react';
import { bifLogoWhite, bifTransLogo } from '../../shared-assets';

export const Logo = ({ className, white = false, size = 36, ...rest }) => (
  <img
    src={white ? bifLogoWhite : bifTransLogo}
    alt="Build it Fab!"
    className={className}
    style={{ maxHeight: size }}
    {...rest}
  />
);
