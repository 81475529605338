"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _debug = _interopRequireDefault(require("debug"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var LogLevel = {
  none: '-',
  all: '*',
  error: 'error',
  info: 'info',
  debug: 'debug',
  warning: 'warning'
};

var Log = function Log() {
  _classCallCheck(this, Log);

  _defineProperty(this, "init", function (level) {
    _debug["default"].enable(level);
  });

  _defineProperty(this, "error", function () {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    (0, _debug["default"])(LogLevel.error)(args.length === 1 ? args[0] : args);
  });

  _defineProperty(this, "info", function () {
    for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      args[_key2] = arguments[_key2];
    }

    (0, _debug["default"])(LogLevel.info)(args.length === 1 ? args[0] : args);
  });

  _defineProperty(this, "debug", function () {
    for (var _len3 = arguments.length, args = new Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
      args[_key3] = arguments[_key3];
    }

    (0, _debug["default"])(LogLevel.warning)(args.length === 1 ? args[0] : args);
  });

  _defineProperty(this, "warn", function () {
    for (var _len4 = arguments.length, args = new Array(_len4), _key4 = 0; _key4 < _len4; _key4++) {
      args[_key4] = arguments[_key4];
    }

    (0, _debug["default"])(LogLevel.warning)(args.length === 1 ? args[0] : args);
  });

  _defineProperty(this, "LogLevel", LogLevel);
};

var _default = new Log();

exports["default"] = _default;