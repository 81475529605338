import { IconButton } from '@material-ui/core';
import React from 'react';
import { PlusCircle } from 'styled-icons/boxicons-regular';
import { palette } from '../../shared-styles/palette';

export const Icon = React.forwardRef((props, ref) => {
  const {
    size = 25,
    color = palette.lightBlueGrey,
    icon: Icon,
    button = false,
    buttonSize = 'medium',
    onClick = () => undefined,
    disabled = false,
    ButtonProps = {},
    ...rest
  } = props;

  if (button) {
    return (
      <IconButton
        onClick={onClick}
        ref={ref}
        size={buttonSize}
        disabled={disabled}
        {...ButtonProps}>
        <Icon size={size} color={color} {...rest} />
      </IconButton>
    );
  }

  return <Icon size={size} color={color} {...rest} />;
});

export const PlusButtonIcon = () => <Icon icon={PlusCircle} color={palette.primary} size={15} />;
