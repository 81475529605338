import { Icon, MasterFormTemplate, PrimaryButton, StyledTextField } from '@bif/components';
import { InputAdornment, Typography } from '@material-ui/core';
import authApi from 'api';
import qs from 'qs';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { routes } from 'route-config';
import { Visibility, VisibilityOff } from 'styled-icons/material-outlined';
import LoginFooter from './login-footer';
import StyledGlobalLoginView, { StyledForm } from './styled-global-login-view';

export default function GlobalResetView() {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);

  const { handleSubmit, control, getValues, errors } = useForm({
    defaultValues: { newPassword: '', confirmPassword: '' },
  });

  const history = useHistory();
  const { search } = useLocation();
  const { token = null } = qs.parse(search, { ignoreQueryPrefix: true });
  if (token === null) return <Redirect to={routes.LOGIN} />;

  const onSubmitReset = ({ confirmPassword }) => {
    setShowPassword(false);
    setIsSubmitting(true);
    authApi
      .post('/auth/resetPassword', { passwordResetId: token, newSecret: confirmPassword })
      .then(({ data }) => {
        console.log('response data', data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsSubmitting(false);
        history.replace(routes.LOGIN);
      });
  };

  return (
    <StyledGlobalLoginView>
      <MasterFormTemplate>
        {isSubmitting ? (
          <Typography variant={'overline'}>Resetting Password...</Typography>
        ) : (
          <Typography variant={'overline'}>Choose your new Password</Typography>
        )}
        <StyledForm onSubmit={handleSubmit(onSubmitReset)} className={'login-form'}>
          <Controller
            disabled={isSubmitting}
            as={StyledTextField}
            autoFocus
            control={control}
            fullWidth
            label="New Password"
            name="newPassword"
            required
            type={showPassword ? 'text' : 'password'}
            variant="filled"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Icon
                    button
                    buttonSize={'small'}
                    color={'secondary'}
                    icon={showPassword ? Visibility : VisibilityOff}
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </InputAdornment>
              ),
            }}
          />
          <Controller
            disabled={isSubmitting}
            as={StyledTextField}
            errors={errors}
            rules={{
              required: 'Please Confirm New Password',
              validate: (value) => {
                return value === getValues()['newPassword'] || 'The passwords do not match';
              },
            }}
            control={control}
            fullWidth
            required
            label="Confirm Password"
            name="confirmPassword"
            type={showPassword ? 'text' : 'password'}
            variant="filled"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Icon
                    button
                    buttonSize={'small'}
                    color={'secondary'}
                    icon={showPassword ? Visibility : VisibilityOff}
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </InputAdornment>
              ),
            }}
          />
          <PrimaryButton type="submit" fullWidth disabled={isSubmitting}>
            Reset Password
          </PrimaryButton>
        </StyledForm>
      </MasterFormTemplate>
      <LoginFooter />
    </StyledGlobalLoginView>
  );
}
