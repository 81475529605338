import { Box, Drawer, Typography } from '@material-ui/core';
import _ from 'lodash-es';
import React from 'react';
import styled from 'styled-components';
import { Close } from 'styled-icons/material';
import { palette } from '../../shared-styles/palette';
import { HrztWrapper, VerticalList } from '../../template-components/box-templates';
import { Icon } from '../../functional-components/atomic-components/icons';
import { PrimaryButton } from '../atomic-components/buttons';
import { StyledTextField } from '../atomic-components/fields';

export const StyledComment = styled(Box)`
  padding-top: 15px;
  padding-bottom: 15px;
  .comment {
    display: flex;
    flex-flow: row wrap;
    background-color: ${palette.formPaper};
    width: 300px;
    min-height: 44px;
    padding: 10px;
    justify-items: center;
    word-break: break-all;
  }

  .comment-meta {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    > * {
      margin-left: 10px;
    }
  }
`;

export const NotesPanel = styled((props) => {
  const { isOpen = false, comments, onClose, onSubmit, onDelete = _.noop } = props;
  const [comment, setComment] = React.useState('');
  const addCompanyComment = () => {
    onSubmit(comment);
    setComment('');
  };
  const removeCompanyComment = (commentIndex) => {
    const nextComments = comments.filter((c, i) => commentIndex !== i);
    onDelete(nextComments);
  };

  const renderComments = React.useMemo(() => {
    return comments.map((c, i) => {
      const { comment, timestamp, userName } = c;
      return (
        <StyledComment key={`company-comment-${i}`}>
          <Box className="comment">
            <Typography>{comment}</Typography>
          </Box>
          <Box className="comment-meta">
            <Typography variant={'caption'}>{userName}</Typography>
            <Typography variant={'caption'}>{timestamp}</Typography>
            <Icon button={true} onClick={() => removeCompanyComment(i)} icon={Close} />
          </Box>
        </StyledComment>
      );
    });
  }, [comments]);

  return (
    <Drawer anchor="right" open={isOpen} className={props.className}>
      <VerticalList className={'notes-list-wrapper'}>
        <HrztWrapper className="header">
          <Typography variant="h3">NOTES</Typography>
          <Icon icon={Close} button={true} onClick={onClose} />
        </HrztWrapper>
        <StyledTextField
          onChange={({ target: { value } }) => setComment(value)}
          fullWidth
          value={comment}
          multiline={true}
          autoFocus={true}
          color="primary"
          rows="3"
          label="Type here"
          rowsMax="6"
        />
        <PrimaryButton onClick={addCompanyComment}>Add Note</PrimaryButton>
        {renderComments}
      </VerticalList>
    </Drawer>
  );
})`
  padding-bottom: 20px;
  .notes-list-wrapper {
    align-items: flex-end;
    min-width: 300px;
    .header {
      width: 100%;
      justify-content: space-between;
    }
    padding: 20px;
  }
`;
