"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isCalledByParent = exports.setCalledByParent = exports.getUrlParams = void 0;

var _constants = require("./constants");

var getUrlParams = function getUrlParams() {
  var params = {};

  if (window.location && window.location.href) {
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
      params[key] = value;
    });
  }

  return params;
};

exports.getUrlParams = getUrlParams;

var setCalledByParent = function setCalledByParent() {
  var urlParams = getUrlParams();
  window.calledByParent = !!urlParams[_constants.ParentCallerUrlParam];
};

exports.setCalledByParent = setCalledByParent;

var isCalledByParent = function isCalledByParent() {
  return !!window.calledByParent || false;
};

exports.isCalledByParent = isCalledByParent;