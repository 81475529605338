import {
  CenteredWrapper,
  PrimaryButton,
  StyledTextField,
  MasterFormTemplate,
} from '@bif/components/dist/index.esm';
import { Typography } from '@material-ui/core';
import qs from 'qs';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { routes } from 'route-config';
import LoginFooter from './login-footer';
import StyledGlobalLoginView, { StyledForm } from './styled-global-login-view';
import authApi from 'api';

export default function GlobalForgotView() {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const { handleSubmit, control } = useForm({
    defaultValues: { username: '' },
  });
  const handleForgotPassword = ({ username }) => {
    setIsSubmitting(true);
    authApi
      .get(`/auth/forgotPassword?${qs.stringify({ username })}`)
      .then(() => setIsSubmitted(true))
      .catch((e) => console.error(e))
      .finally(() => setIsSubmitting(false));
  };

  return (
    <StyledGlobalLoginView>
      <MasterFormTemplate>
        <CenteredWrapper>
          <Link to={routes.LOGIN}>
            <Typography variant="overline">Go To Login</Typography>
          </Link>
        </CenteredWrapper>
        {isSubmitted ? (
          <Typography>
            Thank you, if the email is registered with Build It Fab, you'll receive an email in a
            few minutes to reset your password.
          </Typography>
        ) : (
          <StyledForm onSubmit={handleSubmit(handleForgotPassword)} className={'login-form'}>
            <Controller
              as={StyledTextField}
              autoComplete="email"
              autoFocus={true}
              disabled={isSubmitting}
              control={control}
              fullWidth={true}
              label="Email"
              name="username"
              required={true}
              type="email"
              variant="filled"
            />
            <PrimaryButton type="submit" fullWidth disabled={isSubmitting}>
              Send Reset Password Email
            </PrimaryButton>
          </StyledForm>
        )}
      </MasterFormTemplate>
      <LoginFooter />
    </StyledGlobalLoginView>
  );
}
