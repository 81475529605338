import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
import { fontFaces } from './typography';

export const GlobalStyle = createGlobalStyle`
  ${reset};
  html {
    overflow-y: auto;
    overflow-x: hidden;
  }
  
  html,
  body, 
  #root {
    font-family: ${fontFaces};
    font-size: 14px;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
    text-rendering: geometricPrecision;
  }
  
  #bif-homeowner-root, #bif-builder-root {
    font-family: ${fontFaces};
    font-size: 14px;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
    text-rendering: geometricPrecision;
  }
  
  *,
  *:before,
  *:after {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }
  
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
`;
