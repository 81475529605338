import { clearAuthHeaders } from 'api';
import { LinearProgress } from '@material-ui/core';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { routes } from './route-config';

export default function GlobalLogoutView() {
  const [logoutComplete, setLogoutComplete] = React.useState(false);

  React.useEffect(() => {
    sessionStorage.clear();
    localStorage.clear();
    clearAuthHeaders();
    setLogoutComplete(true);
  }, []);

  return logoutComplete ? <Redirect to={routes.LOGIN} /> : <LinearProgress />;
}
