import { Box } from '@material-ui/core';
import MaterialTable, { MTableCell } from 'material-table';
import * as React from 'react';
import styled from 'styled-components';
import { palette } from '../../shared-styles/palette';
import { tableIcons } from './default-table-icons';
import { TableToolbarTemplate } from './default-toolbar';

const StyledCell = styled(MTableCell)`
  && {
    font-weight: 300;
  }
`;

const defaultComponents = {
  Container: Box,
  Cell: StyledCell,
  Toolbar: TableToolbarTemplate,
};

const defaultDataTableOptions = {
  actionsColumnIndex: -1,
  columnsButton: false, // note: this was broken for some reason
  debounceInterval: 666,
  emptyRowsWhenPaging: false,
  export: true,
  exportAllData: false,
  exportButton: true,
  pageSize: 25,
  pageSizeOptions: [25, 50, 100],
  paginationPosition: 'top',
  paginationType: 'normal',
  paging: true,
  search: true,
  searchFieldAlignment: 'left',
  searchFieldStyle: {},
  showEmptyDataSourceMessage: false,
  showTitle: true,
  sorting: true, // disable sorting feature on col-config if needed
  toolbar: true,
  toolbarButtonAlignment: 'right',
  thirdSortClick: false,
  headerStyle: {
    color: palette.black,
    fontFamily: 'Roboto',
    fontSize: '14px'

  },
  rowStyle: ({ tableData: { id } }) => {
    // const backgroundColor = id % 2 === 0 ? palette.white : palette.tableRowOdd;
    return {
      backgroundColor: palette.tableRowOdd,
      fontFamily: 'Roboto',
      fontSize: '14px'
    };
  },
};

export function StyledTable(props) {
  let { data, columns, components, options = {}, ...rest } = props;
  const _components = {
    ...defaultComponents,
    ...components,
  };
  const _options = {
    ...defaultDataTableOptions,
    ...options,
  };

  return React.useMemo(
    () => (
      <MaterialTable
        columns={columns}
        components={_components}
        data={data}
        icons={tableIcons}
        options={_options}
        {...rest}
      />
    ),
    [data, columns, options, components]
  );
}
