import { Box, Button, LinearProgress, MenuList, Popover, Typography } from '@material-ui/core';
import _ from 'lodash-es';
import React, { useRef } from 'react';
import styled from 'styled-components';
import { AngleDown, AngleUp } from 'styled-icons/fa-solid';
import { Icon } from '../functional-components/atomic-components/icons';
import { bifTheme } from '../shared-styles/bif-mui-theme';
import { palette } from '../shared-styles/palette';

const StyledBox = styled(Box)`
  .account-name {
    line-height: 24px;
    font-size: 14px;
    font-family: "Roboto-Thin', 'Roboto Thin', 'Roboto', sans-serif";
    text-transform: uppercase;
    color: ${palette.white};
    margin-right: ${bifTheme.spacing(2)}px;
  }
`;

export const StyledAccountDropdown = (props) => {
  const { name, menuItems } = props;
  const accountMenuRef = useRef(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const Angle = isOpen ? AngleUp : AngleDown;
  const [truncateLen, setTruncateLen] = React.useState(12);
  const openMenu = () => {
    setIsOpen(true);
    setTruncateLen(30);
  };
  const closeMenu = () => {
    setIsOpen(false);
    setTruncateLen(12);
  };

  if (!name) return <LinearProgress color="secondary" />;
  return (
    <StyledBox>
      <Button onClick={openMenu} ref={accountMenuRef}>
        {name && (
          <Typography className="account-name">
            {_.truncate(name, { length: truncateLen })}
          </Typography>
        )}
        <Icon icon={Angle} color={palette.white} size={20} />
      </Button>
      <Popover
        open={isOpen}
        anchorEl={accountMenuRef?.current}
        elevatation={9}
        onClose={closeMenu}
        disableScrollLock={true}
        marginThreshold={1}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <MenuList autoFocusItem={isOpen} className={'menu-list'}>
          {menuItems}
        </MenuList>
      </Popover>
    </StyledBox>
  );
};
