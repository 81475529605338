import { Box } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { Logo } from '../functional-components/atomic-components/logo';

export const MasterFormTemplate = styled((props) => {
  return (
    <Box className={props.className}>
      <Box className="logo-wrapper">
        <Logo className="logo" size={60} />
      </Box>
      {props.children}
    </Box>
  );
})`
  align-items: center;
  background-color: #fbfbfb;
  border-radius: 10px;
  border: 3px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 3px 3px rgba(255, 255, 255, 0.5);
  display: flex;
  flex-flow: column nowrap;
  height: max-content;
  justify-items: center;
  max-width: 100%;
  opacity: 0.85;
  padding: 20px;

  .login-form {
    padding-top: 10px;
    width: 75%;
  }
  .title-text {
    font-size: 32px;
    font-weight: 300;
    height: 48px;
    line-height: 1.5;
    text-align: center;
    width: 387px;
  }
  .logo-wrapper {
    max-width: 440px;
    width: auto;
    > .logo {
      height: auto;
      width: 100%;
    }
  }
  .left-margin-fix {
    margin: 0;
  }
`;
