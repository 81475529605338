import { createMuiTheme } from '@material-ui/core/styles';
import { palette } from './palette';
import { fontFaces } from './typography';

export const bifThemeConfig = (pal) => ({
  palette: {
    primary: {
      main: pal.primary,
    },
    secondary: {
      main: pal.secondary,
      contrastText: '#ffffff',
    },
    error: {
      main: pal.coral,
    },
  },
  typography: {
    fontFamily: fontFaces,
    button: {
      textTransform: 'unset',
    },
    h1: {
      fontSize: 32,
      lineHeight: '48px',
      fontWeight: 600,
      fontStyle: 'normal',
      letterSpacing: 0,
      color: pal.black,
    },
    h2: {
      fontSize: 24,
      fontWeight: 600,
      fontStyle: 'normal',
      letterSpacing: 0,
      lineHeight: '32px',
      color: pal.black,
    },
    h3: {
      fontSize: 24,
      letterSpacing: 0,
      lineHeight: '32px',
      color: pal.black,
    },
    h4: {
      fontSize: 22,
      // lineHeight: '22px',
      fontWeight: 300,
      letterSpacing: 0,
      textTransform: 'uppercase',
      color: pal.black,
    },
    h5: {
      fontSize: 18,
      // lineHeight: '30px',
      fontWeight: 600,
      letterSpacing: 0,
      color: pal.black,
    },
    h6: {
      fontSize: 16,
      // lineHeight: '30px',
      fontWeight: 300,
      letterSpacing: 0,
      textTransform: 'uppercase',
      color: pal.black,
    },
    body1: {
      fontSize: 14,
      lineHeight: '24px',
      color: pal.black,
    },
    body2: {
      fontSize: 14,
      lineHeight: '24px',
      fontWeight: 600,
      color: pal.black,
    },
    caption: {
      fontSize: 10,
      lineHeight: '12px',
      // fontWeight: 400,
      color: pal.black,
      opacity: 0.5,
    },
    subtitle1: {
      opacity: 0.9,
      color: pal.white,
      lineHeight: '24px',
    },
    subtitle2: {
      opacity: 0.9,
      color: pal.black,
      lineHeight: '16px',
    },
    overline: {
      fontSize: '14px',
      lineHeight: '1rem',
      color: pal.primary,
      textTransform: 'none',
    },
  },
  spacing: 5,
  overrides: {
    MuiTableSortLabel: {
      root: {
        color: pal.secondary,
        '&:hover': {
          color: pal.primary,
        },
      },
      active: {
        color: pal.primary,
      },
    },
    MuiFormLabel: {
      root: {
        // fontSize: "1.2rem"
      },
    },
  },
  props: {
    MuiTooltip: {
      position: 'top',
    },
  },
});

export const bifTheme = createMuiTheme(bifThemeConfig(palette));

console.info(bifTheme);
