import { css } from 'styled-components';

export const fontFaces = 'Roboto-Thin, Roboto Thin,Roboto, sans-serif';
export const typography = {
  h1: css`
    font-size: 32px;
    color: ${({ theme }) => theme.palette.black};
    line-height: 1.5;
    font-weight: 600;
  `,

  h2: css`
    font-size: 24px;
    color: ${({ theme }) => theme.palette.black};
    line-height: 1.33;
    font-weight: 600;
  `,

  navigationSelected: css`
    font-size: 18px;
    color: ${({ theme }) => theme.palette.white};
    line-height: 1.33;
    font-weight: 600;
  `,

  navigationIdle2: css`
    font-size: 18px;
    color: ${({ theme }) => theme.palette.white};
    line-height: 1.33;
    font-weight: book;
  `,

  bodyDefault: css`
    font-size: 18px;
    color: ${({ theme }) => theme.palette.black};
    line-height: 1.33;
    font-weight: book;
  `,

  navigationTitle: css`
    font-size: 16px;
    color: ${({ theme }) => theme.palette.white};
    line-height: 1.25;
    font-weight: 600;
  `,

  navLinks: css`
    color: #bd318c;
    font-size: 15px;
    font-family: ProximaNova;
    font-weight: 700;
  `,

  headerSelected: css`
    font-size: 14px;
    color: #4b74ff;
    line-height: 1.71;
    font-weight: 600;
  `,

  headerDefault: css`
    font-size: 14px;
    color: ${({ theme }) => theme.palette.black};
    line-height: 1.71;
    font-weight: 600;
  `,

  bodyLight: css`
    font-size: 14px;
    color: ${({ theme }) => theme.palette.white};
    line-height: 1.71;
    font-weight: book;
  `,

  bodyAccent: css`
    font-size: 14px;
    color: #4b74ff;
    line-height: 1.71;
  `,

  tagLight: css`
    font-size: 14px;
    color: ${({ theme }) => theme.palette.white};
    line-height: 1.14;
    text-align: center;
    font-weight: 400;
  `,

  tagDark: css`
    font-size: 14px;
    color: ${({ theme }) => theme.palette.black};
    line-height: 1.14;
    text-align: center;
    font-weight: 400;
  `,

  bodyCaption: css`
    font-size: 10px;
    color: ${({ theme }) => theme.palette.black};
    line-height: 1.2;
  `,
};
